<template>
  <div class="content" :style="'background: url('+sgame.backimg+')  0% 0% / 100% no-repeat #f6f6f6;'">
  <!-- 头部 -->
    <div class="headcontent">
      <div class="headcontent-left">
        <img
          :src="sgame.icon"
          alt=""
        />
        <div>
          <p>{{sgame.sgname}}</p>
          <p>奖励以等额彩贝发放</p>
          <p>{{timestamp}}后榜单截止</p>
        </div>
      </div>
      <!-- <div class="headcontent-right">
        <p>我的红包券</p>
        <p>¥{{balancemoney.balance}}</p>
      </div> -->
    </div>
    <div class="rule" @click="profit = true">规则</div>
    <!-- 奖励列表 -->
    <div class="Ranking">
      <div class="head">
        <div>
          <img
            src="https://ppyos.xijiuyou.com/imgs/smartgame/rank02.png"
            alt=""
          />
          <p>NO.{{headlist[1]?headlist[1].rankNote:''}}奖励</p>
          <p>{{headlist[1]?headlist[1].showMoney:''}}<span>{{ unitauthorize('元') }}</span></p>
        </div>
        <div>
          <img
            src="https://ppyos.xijiuyou.com/imgs/smartgame/rank01.png"
            alt=""
          />
          <p>NO.{{headlist[0]?headlist[0].rankNote:''}}奖励</p>
          <p>{{headlist[0]?headlist[0].showMoney:''}}<span>{{ unitauthorize('元') }}</span></p>
        </div>
        <div>
          <img
            src="https://ppyos.xijiuyou.com/imgs/smartgame/rank03.png"
            alt=""
          />
          <p>NO.{{headlist[2]?headlist[2].rankNote:''}}奖励</p>
          <p>{{headlist[2]?headlist[2].showMoney:''}}<span>{{ unitauthorize('元') }}</span></p>
        </div>
      </div>
      <div class="Ranking-lcenter">
        <div class="Ranking-list">
          <template v-for="(item , index) in headlist">
            <div :key="index" v-if="index>2">
              <p>第{{item.rankNote}}名奖励</p>
              <p>{{item.showMoney}}<span>{{ unitauthorize('元') }}</span></p>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 战绩 -->
    <div class="bottom-list" :style="myRank.score=='-'?'padding-top:10px;':''">
      <div class="thelist" v-if="myRank.score!='-'">
        <div>
          <p>{{myRank.ranking=='-'?'未上榜':myRank.ranking}}</p>
          <span>ID:{{myRank.userid}}(我)</span>
        </div>
        <div>{{myRank.score=='-'?0:myRank.score}}分</div>
      </div>
      <div class="bottom-list-top">
        <p
         v-for="(item , index) in seasons" :key="index"
          :style="
            btn == index?'background: url(https://ppyos.xijiuyou.com/imgs/smartgame/pink-button.png) no-repeat; background-size: 100% 100%;color: #ffffff;':''
          "
          @click="check(index,item)"
        >
          {{item.seasonTitle}}
        </p>
      </div>
      <div class="bottom-list-title">榜单排名下拉实时刷新</div>
      <div class="bottom-list-center">
        <div v-for="(item , index) in list" :key="index">
          <div class="bottom-list-center-top">
            <div>
              <img
              v-if="index<3"
                :src="index==0?'https://ppyos.xijiuyou.com/imgs/smartgame/rank-b-01.png':index==1?'https://ppyos.xijiuyou.com/imgs/smartgame/rank-b-02.png':'https://ppyos.xijiuyou.com/imgs/smartgame/rank-b-03.png'"
                alt=""
              />
              <span v-else>{{index+1}}</span>
              <img
                :src="item.headImg!=''?item.headImg:'https://xi9y.oss-cn-hangzhou.aliyuncs.com/sgame/avator/avatar-man.png'"
                alt=""
              />
              <span>{{item.nickName==''?'ID：'+item.userid:item.nickName}}</span>
            </div>
            <p :style="index>=3?'background: #FFEAE8;':'background: #ffffff;'">{{item.score}}分</p>
          </div>
          <div :style="index>=3?'background: rgba(231, 231, 231, 1)!important;':''"></div>
        </div>
      </div>
    </div>
    <!-- 点击去玩游戏 -->
    <div class="fixed">
      <div @click="gogame()">去玩游戏</div>
    </div>
    <!-- 游戏规则 -->
    <xwpopup :isshowpopup="profit" @close="closeprofit()" :hiddenClose="false">
      <img class="closeprofit" src="https://xi9y.oss-cn-hangzhou.aliyuncs.com/sgame/rule/pk.png" alt="">
    </xwpopup>
  </div>
</template>
<script>
import xwpopup from "../../components/Popupbox.vue";
import game from "../../api/game"
import timeutils from "@/utils/TimeFormat";
import unit from "../../utils/unit";
export default {
  components: {
    xwpopup,
  },
  data() {
    return {
      headlist:[],
      seasons:[],
      profit:false,
      btn:0,
      sgame:{},
      timestamp:"",
      stimeStamp:'',
      code:"",
      list:[],
      myRank:{},
      balancemoney:{}
    };
  },
  mounted(){
    const that =this
    this.pklistBase();
    this.balance();
    document.addEventListener('visibilitychange', function(){
      if(!document.hidden){
        that.pklistBase();
        that.balance();
      }
    });
  },
  deactivated(){
    const that =this;
    document.removeEventListener('visibilitychange',function(){
      that.pklistBase();
        that.balance();
    });
  },
  methods:{
    unitauthorize(name){
      return unit.authorize(name)
    },
    // 红包券
    balance(){
      game.balance().then(res=>{
        this.balancemoney = res.data
      })
    },
    // 游戏规则弹窗
    closeprofit(){
      this.profit = false;
    },
    // 点击去玩游戏
    gogame(){
      game.smartstart({gid:this.$route.query.sgid}).then(res=>{
       try{
          window.android.openH5Game(res.data.link)
        }catch(err){
            console.log(err)
        }
      })
    },
    // 今日战绩
    check(index,item){
      this.btn = index
      this.pklistRank(item.seasonCode);
    },
    // 战绩列表
    pklistRank(code){
      game.pklistRank({gid:this.$route.query.sgid,code:code?code:this.code}).then(res=>{
        this.list = res.list;
        this.myRank = res.myRank;
      })
    },
    // 头部数据展示
    pklistBase(){
      game.pklistBase({gid:this.$route.query.sgid}).then(res=>{
        this.headlist = res.list;
        this.seasons = res.seasons;
        this.code = res.seasons[0].seasonCode;
        this.sgame = res.sgame;
        this.stimeStamp = res.stimeStamp-res.timestamp;
        this.timestamp = timeutils.secondToTimeString(this.stimeStamp, true);
        setInterval(() => {
          this.stimeStamp = this.stimeStamp - 1000;
          this.timestamp= timeutils.secondToTimeString(this.stimeStamp, true);
        }, 1000);
        this.pklistRank();
      })
    },
  }
};
</script>

<style scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background: url("https://ppyos.xijiuyou.com/imgs/smartgame/temp01.png")
    no-repeat;
  background-size: 100%;
  padding-top: 28px;
  background-color: #f6f6f6;
}
.headcontent {
  padding: 0 12px;
  margin-bottom: 45px;
}
.headcontent img {
  width: 68px;
  height: 68px;
  margin-right: 14px;
}
.headcontent-left {
  display: flex;
  text-align: center;
}
.headcontent-left div p:nth-child(1) {
  font-size: 20px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}
.headcontent-left div p:nth-child(2) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 6px;
  margin-bottom: 15px;
}
.headcontent-left div p:nth-child(3) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 12px;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}
/* .headcontent-right {
  width: 85px;
  height: 57px;
  background: url("https://ppyos.xijiuyou.com/imgs/smartgame/item-warp.png")
    no-repeat;
  background-size: 100% 100%;
  padding: 6px;
}
.headcontent-right p {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #893f00;
} */
/* .headcontent-right p:nth-child(2) {
  font-size: 14px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: normal;
  color: #893f00;
  margin-top: 10px;
  padding-left: 4px;
} */
.rule {
  width: 24px;
  height: 42px;
  background: rgba(255,255,255,0.7000);
  box-shadow: inset 0px 1px 0px 0px #FFFFFF;
  border-radius: 6px 0px 0px 6px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  padding: 0 2px;
  position: absolute;
  text-align: center;
  line-height: 20px;
  right: 0px;
  top: 50px;
}
.Ranking {
  width: calc(100% - 24px);
  margin: 25px auto 0;
  background: #ffffff;
  border-radius: 20px;
}
.Ranking .head {
  display: flex;
  width: 100%;
}
.Ranking .head div {
  background: linear-gradient(180deg, #dce1ed 0%, #ffffff 100%);
  box-shadow: inset 0px 1px 10px 0px rgba(255, 255, 255, 0.5),
    inset 0px 2px 0px 0px rgba(255, 255, 255, 0.5);
  border-radius: 15px 0px 0px 10px;
  width: 100%;
  text-align: center;
  padding-bottom: 23px;
}
.Ranking .head div img {
  width: 45px;
  height: 45px;
  margin-top: -5px;
}
.Ranking .head div:nth-child(2) img {
  width: 51px;
  height: 51px;
}
.Ranking .head div:nth-child(2) {
  background: linear-gradient(180deg, #f5cf9f 0%, #ffffff 100%);
  border-radius: 10px;
  padding-bottom: 13px;
  margin-top: -13px;
}
.Ranking .head div:nth-child(3) {
  background: linear-gradient(180deg, #f0caba 0%, #ffffff 100%);
  box-shadow: inset 0px 1px 10px 0px rgba(255, 255, 255, 0.5),
    inset 0px 2px 0px 0px rgba(255, 255, 255, 0.5);
  border-radius: 0px 15px 0px 0px;
}
.Ranking .head div p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8490b0;
  margin-top: 7px;
}
.Ranking .head div p:nth-child(3) {
  font-size: 23px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #8490b0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.bottom-list-center-top{
  margin-top: 4px!important;
}
.Ranking .head div p:nth-child(3) span {
  font-size: 16px;
  font-family: AlibabaPuHuiTiB;
  display: flex;
  margin-top: -4px;
}
.Ranking .head div:nth-child(2) p {
  color: rgba(255, 150, 0, 1);
}
.Ranking .head div:nth-child(3) p {
  color: rgba(226, 146, 104, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.Ranking-list {
  border-radius: 20px;
  overflow: hidden;
  width: calc(100% - 28px);
  margin: 0 auto;
}
.Ranking-list div {
  height: 48px;
  background: linear-gradient(180deg, #fffafa 0%, #fff0f0 100%);
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
}
.Ranking-lcenter {
  width: 100%;
  background: #ffffff;
  border-radius: 0 0 15px 15px;
  padding-bottom: 10px;
}
.Ranking-list div p:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.Ranking-list div p:nth-child(2) {
  font-size: 20px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #666666;
}
.Ranking-list div p:nth-child(2) span {
  font-size: 16px;
  font-family: AlibabaPuHuiTiM;
  color: #666666;
}
.bottom-list {
  width: calc(100% - 24px);
  margin: 0 auto;
  background: #ffffff;
  border-radius: 15px;
  margin-bottom: 90px;
  margin-top: 10px;
}
.bottom-list-top {
  width: calc(100% - 28px);
  height: 32px;
  background: #f5f5f5;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.bottom-list-top p {
  width: 100%;
  text-align: center;
  height: 100%;
  line-height: 32px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.bottom-list-title {
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 14px;
  margin-bottom: 16px;
}
.bottom-list-center div div:nth-child(2){
  width: calc(100% - 24px);
  margin: 0 auto;
  height: 1px;
  transform: scaleY(.5);
}
.bottom-list-center div div{
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  margin-bottom: 4px;
}
.bottom-list-center div div {
  background: rgba(0, 0, 0, 0) !important;
  font-size: 14px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ff9600;
  margin: 0;
}
.bottom-list-center div p {
  font-size: 14px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #444444;
  width: 65px;
  text-align: center;
  margin-right: 14px;
  width: 65px;
  height: 22px;
  background: #FFEAE8;
  border-radius: 11px;
  padding-top:3px;
}
.bottom-list-center div:nth-child(1) {
  background: linear-gradient(270deg, #ffffff 0%, #ffedd0 100%);
}
.bottom-list-center div:nth-child(2) {
  background: linear-gradient(270deg, #ffffff 0%, #e5eefb 100%);
}
.bottom-list-center div:nth-child(3) {
  background: linear-gradient(270deg, #ffffff 0%, #ffebe2 100%);
}
.bottom-list-center div img:nth-child(1) {
  width: 38px;
  height: 27px;
  margin-top: -25px;
  margin-left: -2px;
}
.bottom-list-center div img:nth-child(2) {
  width: 30px;
  height: 30px;
  margin-right: 12px;
  border-radius: 50%;
}
.bottom-list-center div span:nth-child(1) {
  display: block;
  width: 38px;
  text-align: center;
  font-size: 14px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #333333;
}
.bottom-list-center div .fen {
  width: 65px;
  height: 22px;
  background: #ffeae8;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thelist {
  width: calc(100% - 28px);
  height: 40px;
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, rgba(255, 86, 117, 0.6) 0%, rgba(255, 116, 113, 0.6) 100%);
  box-shadow: inset 0px 1px 3px 0px rgba(255, 255, 255, 0.3),
    inset 0px -2px 3px 0px rgba(255, 0, 39, 0.3);
  border-radius: 0px 0px 15px 15px;
  margin: 0 auto 10px;
  justify-content: space-between;
  padding: 0 14px;
}
.thelist div {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ffffff;
}
.thelist div:nth-child(2) {
  width: 65px;
  height: 22px;
  background: #ffffff;
  border-radius: 11px;
  font-size: 14px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #444444;
  justify-content: center;
}
.thelist div p {
  width: 45px;
  height: 18px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 9px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5675;
  text-align: center;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixed {
  position: fixed;
  width: 100%;
  height: 68px;
  bottom: 0;
  left: 0;
  background: #ffffff;
}
.fixed div {
  margin: 0 auto;
  margin-top: 10px;
  height: 48px;
  background: linear-gradient(314deg, #ff5675 0%, #ff886d 100%);
  border-radius: 24px;
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.closeprofit{
  width: calc(100% - 100px);
  margin-top: 80px;
}
</style>