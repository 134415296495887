const utils = {
  secondToTimeString (second, needSecond = false){
    let rtn = ''
    // 去零取整的小时数
    const hours = Math.floor(second / 3600000)
    // 去零取整的分钟数
    const mins = Math.floor((second / 1000 - hours * 3600) / 60)
    rtn = `${hours < 10 ? `0${hours}` : hours}:${mins < 10 ? `0${mins}` : mins}`
    if (needSecond) {
      const sec = Math.floor(second / 1000 - hours * 3600 - mins * 60)
      rtn = `${rtn}:${sec < 10 ? `0${sec}` : sec}`
    }
    return rtn
  }
}

export default utils
